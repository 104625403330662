/* ===== SocialMediaLinksList */
.tableContainer {
  margin-top: 20px;

  .table {
    width: 100%;
    table-layout: fixed;

    .tableCellSocialMedia {
      width: 50px;
      padding: 0;
      div {
        display: flex;
        align-items: center;
        width: fit-content;
        gap: 1rem;
        span {
          display: none;
        }
      }

      @media screen and (min-width: 768px) {
        width: auto;
        padding: 16px;
        div {
          span {
            display: flex;
          }
        }
      }
    }

    .tableCellUrl {
      min-width: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
      padding: 0;

      @media screen and (min-width: 768px) {
        padding: 16px;
      }
    }
  }
}
