.headerContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-inline: 1rem;
  background-color: #f9f9f9;
  width: 100%;
  margin-bottom: 1rem;
}

.buttonGoBack {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
  transition: color ease 0.2s;
  &:hover {
    color: #ddd;
  }
}

.productImage {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #ddd;

  @media screen and (min-width: 768px) {
    width: 80px;
    height: 80px;
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.clientName {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.clientName span {
  font-weight: normal;
}

.productName {
  font-size: 0.875rem;
  color: #757575;
  margin: 0;
}
