.ecommerceConsultancyContainer {
  border-radius: 16px;
  flex: 1 1 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 0 2px 0 rgba(145 158 171 / 0.2),
    0 12px 24px -4px rgba(145 158 171 / 0.12);
}

.ecommerceConsultancyContent {
  min-width: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
}
