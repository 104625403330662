$grey: #f7f7f7;
$light-grey: #e9e9e9;
$dark-grey: #606060;
$almost_white: #f2f2f2;
$yellow: #fdd400;

.no-scroll {
  overflow: hidden;
}

.pacifico-regular {
  font-family: "Pacifico", cursive;
  font-weight: 400;
  font-style: normal;
}

// This button is always the next button.
.next {
  margin-top: 0.5rem;
  background-color: #fdd400;
  color: black;
  padding: 0.5rem 1.5rem;
  border-radius: 10px;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
  font-family: "Open sans", sans-serif;
  border: none;
}

// Form error messages
.error {
  font-size: 0.8rem;
  color: red !important;
  padding: 1rem;
}

// Fixes bug with material-table header
.MuiTableRow-root > th {
  z-index: 0 !important;
}

// buttons for forms, consider moving these to global
// since they're being used multiple times
.btn-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  .signup-next-button,
  .signup-back-button {
    display: flex;
    margin: 0.5rem;
    background-color: $yellow;
    color: black;
    padding: 0.7rem 2rem;
    font-size: 1.25rem;
    border: none;
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
    i.fa-angle-right {
      margin-left: 0.5rem;
    }
    i.fa-angle-left {
      margin-right: 0.5rem;
    }
  }
}

.designer-container {
  max-width: 1024px;
  width: 100%;
  margin: auto;
  position: relative;
  padding-inline: 0.65rem;
}
// grid container
.grid-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;

  @media screen and (min-width: 639px) {
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 30px;
    justify-content: space-between;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 30px;
    justify-content: flex-start;
    column-gap: 5%;
  }
}
a {
  text-decoration: none;
}

// Edit Product Details section

.product-edit_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 0%;
  overflow-y: auto;
  margin-top: 2rem;
  padding-bottom: 2.5rem;
  padding-inline: 1.25rem;

  @media (min-width: 768px) {
    padding-bottom: 3.5rem;
    padding-inline: 3.5rem;
  }
}

.paperFormContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  flex: 1 1 0;
  @media (min-width: 768px) {
    padding: 2rem;
  }
}

//******* ----- Dashboard styles ------  *********//

.header-container {
  margin-bottom: 24px;

  @media screen and (min-width: 900px) {
    margin-bottom: 40px;
  }
}

.header-title {
  font-size: 1.25rem;
  line-height: 1.5;
  color: var(--text-color);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  margin-bottom: 16px;

  @media screen and (min-width: 900px) {
    font-size: 1.5rem;
  }
}

.layout-dashboard-container {
  width: 100%;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding-inline: 16px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding-top: 6rem;
  padding-bottom: 64px;

  @media screen and (min-width: 600px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media screen and (min-width: 1200px) {
    padding-left: 40px;
    padding-right: 40px;
    max-width: 1200px;
  }
}

#swal2-title.swal2-title {
  font-size: 1.5rem;
  line-height: 2rem;
}

button.swal2-confirm.swal2-styled {
  background-color: var(--primary-color);
}

.formProductContainer {
  width: 100%;
}

.formProductSaveButton {
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.formSalesPersonButton {
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.navLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.95rem;
  text-transform: capitalize;
  font-weight: 500;
  min-height: 44px;
  border-radius: 8px;
  padding: 4px 8px 4px 12px;
  margin-top: 4px;
  cursor: pointer;
  color: #637381;
  width: 100%;
  .navLinkContent {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-start;
  }

  &:hover {
    background-color: rgba(122, 124, 128, 0.07);
  }

  .iconLink {
    font-size: 1rem;
    height: 24px;
    width: 24px;

    svg {
      color: #637381 !important;
      width: 100%;
      height: 100%;
    }
  }
}

.subMenuLink {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  font-size: 0.95rem;
  text-transform: capitalize;
  font-weight: 500;
  min-height: 44px;
  border-radius: 8px;
  padding: 4px 8px 4px 12px;
  margin: 0px 0px 4px;
  cursor: pointer;
  color: #637381;
  width: 100%;

  &:hover {
    background-color: rgba(122, 124, 128, 0.07);
  }
}

.MuiBadge-badge {
  color: #fff;
  background-color: var(--primary-color);
}

.navlinkActive {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.95rem;
  text-transform: capitalize;
  font-weight: 600;
  min-height: 44px;
  border-radius: 8px;
  padding: 4px 8px 4px 12px;
  cursor: pointer;
  color: rgb(38, 50, 56);
  width: 100%;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  .navLinkContent {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-start;
  }

  background-color: rgba(163, 175, 199, 0.25);

  &:hover {
    background-color: rgba(163, 175, 199, 0.6);
  }
  .iconLink {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    height: 24px;
    width: 24px;

    div {
      color: rgb(38, 50, 56) !important;
    }

    svg {
      color: rgb(38, 50, 56) !important;
      width: 100%; // Cambia esto por el ancho que quieras
      height: 100%; // Cambia esto por la altura que quieras
    }
  }
}

.subMenuLinkActive {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  font-size: 0.95rem;
  text-transform: capitalize;
  font-weight: 600;
  min-height: 44px;
  border-radius: 8px;
  padding: 4px 8px 4px 12px;
  margin: 0px 0px 4px;
  cursor: pointer;
  color: rgb(38, 50, 56);
  width: 100%;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  background-color: rgba(122, 124, 128, 0.07);

  &:hover {
    background-color: rgba(122, 124, 128, 0.07);
  }
}

.navLinkCollapsed {
  background-color: rgba(145 158 171 / 0.08);
}

//******* ----- styles React Image Galery library  ------  *********//

.image-gallery {
  width: 100%;
  @media (min-width: 768px) {
    width: fit-content;
  }
}
.image-gallery-slides {
  width: 100%;
  .image-gallery-slide {
    width: 100%;
    height: 372px;
    display: flex !important;
    align-items: center;
    justify-content: center;

    .image-gallery-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (min-width: 768px) {
      width: 516px;
      height: 516px;
    }
  }
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  text-align: justify;
}

.image-gallery-thumbnail {
  height: 84px;
  width: 84px;

  .image-gallery-thumbnail-inner {
    width: 100%;
    height: 100%;

    .image-gallery-thumbnail-image {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }
}

.image-gallery-thumbnail.active {
  border-color: #3483fa;
  border-width: 2px;
}

//******* ----- End React Image Gallery library ------  *********//
