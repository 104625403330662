html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.container-video-call {
  width: 100%;
  height: 100vh;
}
.App-header {
  background-color: #222;
  height: 40px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.App-header h1 {
  font-size: 16px;
  font-weight: 200;
}

.App-logo {
  height: 60%;
  width: auto;
}

.App-main {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border: 1px solid rgb(231, 249, 255);
}

.App-control-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5f5a5a;
  width: 100%;
  margin: 0 auto;
  gap: 0.5rem;
}

.App-control-container.hidden {
  display: none;
}

.App-control-container .ots-video-control {
  width: 50px;
  height: 50px;
  margin: 20px 0 !important;
  border-radius: 50%;
  background-position: center;
  background-color: rgb(82, 80, 80);
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 5;
  box-shadow: 0.8px 5px 5px 0px rgba(0, 0, 0, 0.5);
  transition: ease-in-out 0.2s;
}

@media screen and (min-width: 1024px) {
  .App-control-container .ots-video-control:hover {
    background-color: #fdd440;
  }
}

.App-control-container .ots-video-control.audio {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-mic.png);
}

/* TODO: Find the way to add hover effect in both control buttons without overlap the functions */
/* .App-control-container .ots-video-control.audio:hover, */
.App-control-container .ots-video-control.audio.muted {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-muted-mic.png);
}

.App-control-container .ots-video-control.video {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-video.png);
}

.App-control-container .ots-video-control.video.muted {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-no-video.png);
}

.App-control-container .ots-video-control.end-call {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-hang-up.png);
  background-color: red;
}

.App-video-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-mask {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  color: white;
  font-size: 2rem;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App-mask .App-logo-video {
  width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-mask .App-logo-video > img {
  width: 100%;
  height: auto;
}

.App-mask .App-mask__content {
  width: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.App-mask .App-mask__content span {
  font-size: 1.1rem;
  font-weight: bold;
  color: #222;
  margin-bottom: 0.8rem;
}
.App-mask .App-mask__content p {
  font-size: 0.8rem;
  color: #323232;
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .App-mask .App-logo-video {
    width: 600px;
    height: auto;
  }
}

.App-mask .react-spinner {
  position: absolute;
}

.App-mask .message {
  font-weight: 200;
}

.App-mask .message.with-spinner {
  position: absolute;
  top: 45%;
}

.App-mask .message.button {
  border: 2px solid white;
  padding: 20px 40px;
  border-radius: 6px;
  background-color: Transparent;
  color: white;
  font-family: sans-serif;
  font-size: medium;
}

.App-mask .message.button:hover {
  cursor: pointer;
}

.App-mask .message.button:focus:active,
.message.button:focus,
.message.button:active {
  background-image: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.App-video-container .video-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.App-video-container .video-container.small {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 160px;
  height: 96px;
  border: 1px solid #fcba00;
  z-index: 2;
}

.App-video-container .video-container.small.left {
  left: 20px;
  border: 1px solid #00fcc2;
}

.App-video-container .video-container.hidden {
  display: none;
}

.App-video-container .video-container.active-gt2 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.App-video-container .video-container.active-gt2.hidden {
  display: none;
}

.App-video-container .video-container.active-gt2 .OT_subscriber {
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: 0;
  flex-basis: 50%;
  box-sizing: border-box;
  width: auto !important;
  height: auto !important;
}

.App-video-container
  .video-container.active-gt2.active-odd
  .OT_subscriber:first-child {
  flex-basis: 100%;
}

.ots-app-container {
  width: 90vw;
  height: 90vh;
  min-width: 720px;
  min-height: calc(720px / (1024 / 760));
  margin: 35px auto;
  padding: 15px;
  box-sizing: border-box;
}

.ots-hidden {
  display: none !important;
}

.ots-app-container *,
.ots-app-container *:before,
.ots-app-container *:after {
  box-sizing: inherit;
}

.ots-widget-container {
  width: 100%;
  height: 100%;
  font-family: helvetica, arial, sans-serif;
  border: 1px solid rgba(87, 193, 228, 0.85);
  border-radius: 6px;
  padding: 5px;
}

/* Moves text chat next to the primary video feed */

.ots-widget-container.display-text-chat {
  display: -webkit-box;
  display: flex;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkitalign-items: center;
  align-items: center;
}

.ots-widget-container.viewing-shared-screen {
  padding-bottom: 65px;
}

.ots-widget-container .video-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.ots-widget-container .video-container.partial {
  width: 65%;
}

.ots-widget-container .chat-container {
  width: 30%;
}

.ots-icon-check {
  background-image: url("https://assets.tokbox.com/solutions/images/text-chat-check.png");
  background-repeat: no-repeat;
  background-position: center center;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min--moz-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .ots-icon-check {
    background-image: url("https://assets.tokbox.com/solutions/images/text-chat-check@2x.png");
    background-size: 27px 21px;
  }
}

/* Aspect Ratio for Screen Sharing */

.ots-aspect-ratio {
  height: 54vw;
  min-width: 600px;
  min-height: 360px;
  padding: 0;
}

.ots-aspect-ratio > .video-container {
  position: absolute;
}

.ots-widget-container .shared-screen {
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
}

.ots-widget-container .shared-screen canvas {
  top: 0;
  left: 0;
}

.ots-widget-container .sharing-mask {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.8);
}

.ots-widget-container .sharing-mask .sharing-text {
  color: lightblue;
  font-size: 1.2em;
  font-weight: 300;
  text-align: center;
}

.ots-widget-container .viewing-shared-mask {
  position: absolute;
  height: 100%;
  width: 100%;
  background: black;
  z-index: 2;
}

.ots-widget-container .video-wrap.shared-screen {
  left: 0;
  right: 0;
  background: #000;
  margin: 0 auto;
}

.ots-widget-container .video-wrap.secondary-video .OT_publisher {
  position: absolute;
  top: 0;
}

.ots-widget-container .video-background {
  background-image: url("https://assets.tokbox.com/solutions/images/widget-background.png");
  background-size: contain;
}

.ots-widget-container .primary-video {
  width: 100%;
  height: 100%;
  display: flex !important;
}

.ots-widget-container .local-controls {
  height: 60px;
  width: 100%;
  background-image: url("https://assets.tokbox.com/solutions/images/widget-control-bar.png");
  background-size: contain;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 6px 6px;
  display: -webkit-box;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  z-index: 1001;
}

.ots-widget-container .secondary-video {
  position: absolute;
  bottom: 75px;
  right: 30px;
  width: 174px;
  height: 150px;
  z-index: 11;
}

.ots-widget-container .secondary-video.active {
  border: 1px solid rgba(255, 255, 255, 0.8);
}

/* Move down so as to not cover canvas */

.ots-widget-container .local-controls.viewing-shared-screen {
  height: 65px;
  bottom: -60px;
}

.ots-widget-container .secondary-video {
  position: absolute;
  bottom: 75px;
  right: 30px;
  width: 174px;
  height: 150px;
  z-index: 11;
}

.ots-widget-container .secondary-video.active {
  border: 1px solid rgba(255, 255, 255, 0.8);
}

.ots-widget-container .remote-controls {
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 12;
}

.ots-widget-container .ots-video-control {
  height: 50px;
  width: 50px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.ots-widget-container .ots-video-control.circle {
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 50%;
}

.ots-widget-container .ots-video-control.circle:hover {
  border: 1px solid rgba(128, 128, 128, 1);
}

.ots-widget-container .ots-video-control.call {
  background-color: rgba(87, 193, 228, 0.85);
  background-image: url("https://assets.tokbox.com/solutions/images/icon-call.png");
  margin: 0 15px;
}

.ots-widget-container .ots-video-control.call:hover {
  background-color: rgba(87, 193, 228, 1);
}

.ots-widget-container .ots-video-control.call.active {
  background-color: red;
  background-image: url("https://assets.tokbox.com/solutions/images/icon-hang-up.png");
}

.ots-video-control.text-chat {
  margin-left: 10px;
  background-image: url("https://assets.tokbox.com/solutions/images/icon-message.png");
  /* filter: invert(100%); */
}

.ots-video-control.text-chat.disabled,
.ots-video-control.text-chat:hover {
  background-image: url("https://assets.tokbox.com/solutions/images/icon-message.png");
}

.ots-video-control.text-chat.active {
  background-color: brown;
  background-image: url("https://assets.tokbox.com/solutions/images/icon-message.png");
}

.ots-video-control.archiving {
  margin-left: 10px;
  background-image: url("https://assets.tokbox.com/solutions/images/icon-not-recording.png");
}

.ots-video-control.archiving.active,
.ots-video-control.archiving:hover {
  background-image: url("https://assets.tokbox.com/solutions/images/icon-recording.png");
}

.ots-video-control.share-screen {
  margin-left: 10px;
  background-image: url("https://assets.tokbox.com/solutions/images/icon-screen-share.png");
}

.ots-video-control.share-screen.disabled,
.ots-video-control.share-screen:hover {
  background-image: url("https://assets.tokbox.com/solutions/images/icon-screen-share.png");
}

.ots-video-control.share-screen.active {
  background-color: red;
  background-image: url("https://assets.tokbox.com/solutions/images/icon-screen-share.png");
}

/*Text EcommerceChat */

.ots-text-chat {
  position: relative;
  margin-top: 20px;
  border: 1px solid #c6c6c6;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #fff;
  font-family: helvetica, arial, sans-serif;
  color: #343c3e;
  font-weight: normal;
}

.ots-text-chat .ots-messages-header {
  width: 100%;
  display: block;
  text-align: center;
  line-height: 28px;
  font-size: 13px;
  color: #9f9f9f;
  border-bottom: 1px solid #c6c6c6;
  background: #f2f2f2;
  -webkit-border-top-right-radius: 2px;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topright: 2px;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 2px;
  -moz-background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.ots-text-chat .ots-messages-header.hidden {
  display: none;
}

.ots-text-chat .ots-messages-holder {
  height: 365px;
  overflow: hidden;
  overflow-y: scroll;
}

.ots-text-chat .ots-messages-holder.has-alert {
  padding-top: 30px;
}

.ots-text-chat .ots-messages-holder .ots-messages-alert {
  position: relative;
  padding: 30px;
  min-height: 65px;
  background: rgba(248, 231, 28, 0.25);
  margin: 25px;
  text-align: center;
  color: #ad7212;
  font-size: 14px;
  line-height: 1.4;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.ots-text-chat .ots-messages-holder .ots-messages-alert.green {
  color: #417505;
  background-color: rgba(126, 210, 33, 0.3);
}

.ots-text-chat .ots-messages-holder .ots-messages-alert.red {
  color: #c41f4b;
  background-color: rgba(210, 33, 33, 0.3);
}

.ots-text-chat .ots-messages-holder .ots-message-item {
  position: relative;
  padding: 0 0 0 50px;
  margin: 40px 25px 10px 20px;
}

.ots-text-chat .ots-messages-holder .ots-message-item .ots-user-name-initial {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  text-transform: uppercase;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #b3b3b3;
  color: #fff;
  font-size: 24px;
}

.ots-text-chat .ots-messages-holder .ots-message-item .ots-item-text {
  position: relative;
  padding: 12px;
  min-height: 65px;
  background: #f2f2f2;
  margin-left: 14px;
  color: #7c7c7c;
  font-size: 15px;
  line-height: 1.2;
  -webkit-border-top-right-radius: 8px;
  -webkit-border-bottom-right-radius: 8px;
  -webkit-border-bottom-left-radius: 8px;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 8px;
  -moz-border-radius-bottomright: 8px;
  -moz-border-radius-bottomleft: 8px;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 0;
  -moz-background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.ots-text-chat .ots-messages-holder .ots-message-item .ots-item-text:before {
  content: "";
  position: absolute;
  top: 0;
  left: -12px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 14px 14px 0;
  border-color: transparent #f2f2f2 transparent transparent;
}

.ots-text-chat .ots-messages-holder .ots-message-item .ots-item-text span {
  display: block;
  margin-bottom: 6px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.ots-text-chat .ots-messages-holder .ots-message-item .ots-item-timestamp {
  position: absolute;
  top: -18px;
  right: 20px;
  color: #b3b3b3;
  font-size: 12px;
}

.ots-text-chat .ots-messages-holder .ots-message-item.ots-message-sent {
  padding: 0 50px 0 0;
  margin: 40px 20px 10px 25px;
}

.ots-text-chat
  .ots-messages-holder
  .ots-message-item.ots-message-sent
  .ots-user-name-initial {
  left: inherit;
  right: 0;
  background: #259de8;
}

.ots-text-chat
  .ots-messages-holder
  .ots-message-item.ots-message-sent
  .ots-item-text {
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 8px;
  -webkit-border-bottom-left-radius: 8px;
  -webkit-border-top-left-radius: 8px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 8px;
  -moz-border-radius-bottomleft: 8px;
  -moz-border-radius-topleft: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  -moz-background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  margin-left: 0;
  margin-right: 14px;
}

.ots-text-chat
  .ots-messages-holder
  .ots-message-item.ots-message-sent
  .ots-item-text:before {
  left: inherit;
  right: -12px;
  border-width: 14px 14px 0 0;
  border-color: #f2f2f2 transparent transparent transparent;
}

.ots-text-chat
  .ots-messages-holder
  .ots-message-item.ots-message-sent
  .ots-item-timestamp {
  right: 80px;
}

.ots-text-chat .ots-send-message-box {
  position: relative;
  width: 100%;
  height: 70px;
  border-top: 1px solid #c6c6c6;
  padding-right: 70px;
}

.ots-text-chat .ots-send-message-box input[type="text"],
.ots-text-chat .ots-send-message-box textarea {
  width: 100%;
  border: 0;
  outline: 0;
  background: 0;
  padding: 25px 25px 0;
  font-size: 16px;
  resize: none;
}

.ots-text-chat .ots-send-message-box button {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 69px;
  background-color: #f2f2f2;
  border-left: 1px solid #c6c6c6;
  outline: 0;
  border: 0;
  border-left: 1px solid #c6c6c6;
}

.ots-text-chat .ots-send-message-box button:hover {
  background-color: #259de8;
}

.ots-text-chat .ots-send-message-box .ots-character-count {
  position: absolute;
  bottom: -18px;
  right: 0;
  color: #cf2027;
  font-size: 12px;
}

.ots-text-chat .ots-user-prof-info {
  width: auto;
  height: 430px;
  padding: 32px 46px;
}

.ots-text-chat .ots-user-prof-info span {
  display: block;
  margin-bottom: 10px;
  color: #5f5a5a;
}

.ots-text-chat .ots-user-prof-info span em {
  color: #867f7f;
  font-style: normal;
}

.ots-text-chat .ots-user-prof-info hr {
  margin: 36px 0;
}

/* Screensharing styles */

.ots-ss-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.ots-ss-modal .ots-modal-body {
  position: relative;
  width: 456px;
  height: 286px;
  margin: 100px auto 0;
  padding: 62px 70px;
  background: #ffffff;
  border: 1px solid #c7c7c7;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.35);
  color: #282828;
}

.ots-ss-modal .ots-modal-body .ots-modal-title span {
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
}

.ots-ss-modal .ots-modal-body .ots-modal-title.with-icon {
  position: relative;
  padding-left: 70px;
}

.ots-ss-modal .ots-modal-body .ots-modal-title.with-icon i {
  position: absolute;
  top: 50%;
  left: 0;
  width: 60px;
  height: 41px;
  margin-top: -23px;
  max-width: 60px;
  max-height: 42px;
}

.ots-ss-modal .ots-modal-body p {
  font-size: 14px;
  margin: 20px 0;
}

.ots-ss-modal .ots-modal-body .ots-btn-install {
  display: block;
  width: 316px;
  height: 44px;
  margin: 0 auto;
  text-align: center;
  line-height: 44px;
  color: #fff;
  font-size: 14px;
  background: #259de8;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-decoration: none;
}

.ots-ss-modal .ots-modal-body .ots-cancel-btn-install {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 25px;
  height: 25px;
  background-image: url("https://assets.tokbox.com/solutions/images/icon-close.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min--moz-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .ots-ss-modal .ots-modal-body .ots-cancel-btn-install {
    background-image: url("https://assets.tokbox.com/solutions/images/icon-close@2x.png");
    background-size: 25px 25px;
  }
}

.ots-icon-share-large {
  background-image: url("https://assets.tokbox.com/solutions/images/icon-screenshare-large.png");
  background-repeat: no-repeat;
  background-position: center center;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min--moz-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .ots-icon-share-large {
    background-image: url("https://assets.tokbox.com/solutions/images/icon-screenshare-large@2x.png");
    background-size: 60px 41px;
  }
}

/*Annotation Styles*/

.ots-annotation-toolbar-container {
  position: fixed;
  top: 125px;
  right: 0;
  width: 60px;
  z-index: 1000;
  background-color: #666666;
}

.ots-annotation-toolbar-container .OT_panel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ots-annotation-toolbar-container .OT_panel.colors-hover:before {
  content: "";
  position: absolute;
  top: 60px;
  height: 60px;
  width: 60px;
  background-color: #323232;
  z-index: -1;
}

.ots-annotation-toolbar-container .annotation-btn {
  height: 60px;
  width: 60px;
  background-position: center center;
  background-repeat: no-repeat !important;
  background-color: #666666;
  cursor: pointer;
  border: none;
}

.ots-annotation-toolbar-container .annotation-btn:hover,
.ots-annotation-toolbar-container .annotation-btn.selected {
  background-color: #323232;
}

.ots-annotation-toolbar-container .annotation-btn.pen {
  background-image: url(https://assets.tokbox.com/solutions/images/annotation-pencil.png);
  background-size: 27px 30px;
}

.ots-annotation-toolbar-container .annotation-btn.colors {
  width: 23px;
  height: 24px;
  border: 3px solid white;
  margin: 18px 18.5px;
}

.ots-annotation-toolbar-container .annotation-btn.line {
  background-image: url(https://assets.tokbox.com/solutions/images/annotation-line.png);
  background-size: 26px 31px;
}

.ots-annotation-toolbar-container .annotation-btn.line-width {
  background-image: url(https://assets.tokbox.com/solutions/images/annotation-line_width.png);
  background-size: 26px 31px;
}

.ots-annotation-toolbar-container .annotation-btn.shapes {
  background-image: url(https://assets.tokbox.com/solutions/images/annotation-shapes.png);
  background-size: 26px 31px;
}

.ots-annotation-toolbar-container .annotation-btn.text {
  background-image: url(https://assets.tokbox.com/solutions/images/annotation-text.png);
  background-size: 21px 25px;
}

.ots-annotation-toolbar-container .annotation-btn.capture {
  background-image: url(https://assets.tokbox.com/solutions/images/annotation-camera.png);
  background-size: 34px 31px;
}

.ots-annotation-toolbar-container .annotation-btn.clear {
  background-image: url(https://assets.tokbox.com/solutions/images/annotation-trash.png);
  background-size: 30px 30px;
}

.ots-annotation-toolbar-container .annotation-btn.undo {
  background-image: url(https://assets.tokbox.com/solutions/images/annotation-undo.png);
  background-size: 34px 31px;
}

.ots-annotation-toolbar-container .OT_subpanel,
.ots-annotation-toolbar-container .color-picker {
  position: absolute;
  right: 65px;
  width: 40px;
  background-color: #333333;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity 0.5s ease-out;
}

.ots-annotation-toolbar-container .OT_subpanel.pen {
  top: 0px;
  /*transition: visibility 0s linear 0.5s;*/
}

.ots-annotation-toolbar-container .OT_subpanel.pen .line-width-option {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.ots-annotation-toolbar-container .OT_subpanel.pen:after {
  position: absolute;
  top: 15px;
  right: -15px;
  content: "";
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #333333;
}

.ots-annotation-toolbar-container .color-picker {
  top: 0;
}

.ots-annotation-toolbar-container .color-picker:after {
  position: absolute;
  top: 75px;
  right: -15px;
  content: "";
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #333333;
}

.ots-annotation-toolbar-container .color-picker .color-choice {
  width: 20px;
  height: 20px;
  margin: 10px;
  cursor: pointer;
  border-radius: 100%;
}

.ots-annotation-toolbar-container .color-picker .color-choice.active {
  border: 2px solid white;
}

.ots-annotation-toolbar-container .OT_subpanel.shapes {
  display: flex;
  flex-direction: column;
  top: 75px;
  min-height: 159.89px;
}

.ots-annotation-toolbar-container .OT_subpanel.shapes:after {
  position: absolute;
  top: 62.5px;
  right: -15px;
  content: "";
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #333333;
}

.ots-annotation-toolbar-container .OT_subpanel.shapes input {
  width: 22px;
  height: 22px;
  margin: 9px;
  border: none;
  background-color: #333333;
  background-repeat: no-repeat;
  background-position: center center;
}

.ots-annotation-toolbar-container
  .OT_subpanel.shapes
  .annotation-btn.rectangle {
  background-image: url(https://assets.tokbox.com/solutions/images/annotation-rectangle.png);
  background-size: 18px 18px;
}

.ots-annotation-toolbar-container
  .OT_subpanel.shapes
  .annotation-btn.rectangle-fill {
  background-image: url(https://assets.tokbox.com/solutions/images/annotation-rectangle-fill.png);
  background-size: 18px 18px;
}

.ots-annotation-toolbar-container .OT_subpanel.shapes .annotation-btn.oval {
  background-image: url(https://assets.tokbox.com/solutions/images/annotation-oval.png);
  background-size: 20px 20px;
}

.ots-annotation-toolbar-container
  .OT_subpanel.shapes
  .annotation-btn.oval-fill {
  background-image: url(https://assets.tokbox.com/solutions/images/annotation-oval-fill.png);
  background-size: 20px 20px;
}

.ots-annotation-toolbar-container .OT_subpanel.shapes .annotation-btn.star {
  background-image: url(https://assets.tokbox.com/solutions/images/annotation-star.png);
  background-size: 22px 22px;
}

.ots-annotation-toolbar-container .OT_subpanel.shapes .annotation-btn.arrow {
  background-image: url(https://assets.tokbox.com/solutions/images/annotation-arrow.png);
  background-size: 6.5px 23.5px;
}

.ots-annotation-prompt {
  background-color: rgba(1, 1, 1, 0.5);
  width: 200px;
  border-radius: 5px;
}

.ots-annotation-prompt span {
  display: block;
  float: left;
  width: 100px;
  height: 40px;
  font-size: 12px;
  padding: 10px;
  color: white;
  line-height: 20px;
  padding-right: 0px;
}

.ots-annotation-prompt .dismiss-btn {
  display: block;
  width: 40px;
  height: 36px;
  background-image: url(https://assets.tokbox.com/solutions/images/annotation-trash.png);
  float: left;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 65%;
  cursor: pointer;
}

.ots-annotation-prompt .confirm-btn {
  display: block;
  width: 40px;
  height: 36px;
  background-image: url(https://assets.tokbox.com/solutions/images/annotation-confirm.png);
  float: right;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 70%;
  cursor: pointer;
}

/* Archiving */

.ots-archiving-modal {
  position: fixed;
  /* Stay in place */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  z-index: 1051;
  font-family: Arial, Helvetica, sans-serif;
}

.ots-archiving-modal * {
  box-sizing: border-box;
}

.ots-archiving-modal .modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border-top: 2px solid #33abf5;
  width: 710px;
  height: 265px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.ots-archiving-modal .modal-content > div {
  display: flex;
  flex-direction: column;
  height: 33.33%;
  justify-content: center;
  align-items: center;
}

.ots-archiving-modal .modal-header h2 {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 200;
  font-size: 20px;
}

.ots-archiving-modal .modal-header h2::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  left: -15px;
  bottom: -15px;
  background-image: url(http://assets.tokbox.com/solutions/images/icon-download.png);
  background-size: contain;
  background-position: 2px -5px;
  background-repeat: no-repeat;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min--moz-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .ots-archiving-modal .modal-header::before {
    background-image: url("https://assets.tokbox.com/solutions/images/icon-download@2x.png");
  }
}

.ots-archiving-modal .close-button {
  position: absolute;
  background-image: url("https://assets.tokbox.com/solutions/images/icon-close-thin.png");
  background-size: contain;
  height: 20px;
  width: 20px;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.ots-archiving-modal .close:hover,
.ots-archiving-modal .close:focus {
  color: #000;
  text-decoration: none;
}

.ots-archiving-modal .modal-info {
  background-color: #fafbfc;
  border: 1px solid #e8e8e8;
  padding: 20px;
  font-size: 16px;
}

.ots-archiving-modal .modal-info > div {
  margin: 5px auto;
  font-family: Muli;
}

.ots-archiving-modal .modal-info .message {
  font-size: 13px;
  font-weight: 100;
  line-height: 18px;
  width: 80%;
}

.ots-archiving-modal .modal-info .archive-id {
  width: 70%;
  font-weight: 200;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ots-archiving-modal .modal-info .archive-details {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: 600;
  width: auto;
}

.ots-archiving-modal .modal-button-container {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ots-archiving-modal .btn {
  display: inline-block;
  font-family: helvetica, arial, sans-serif;
  width: 145px;
  height: 35px;
  line-height: 35px;
  color: white;
  text-decoration: none;
  border: 1px solid rgba(68 68 68, 1);
  font-size: 14px;
  font-weight: 100;
  cursor: pointer;
}

.ots-archiving-modal .btn.download,
.ots-archiving-modal .btn.ok {
  background-color: #4a90e2;
}
