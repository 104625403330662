.chatContainer {
  display: flex;
  flex-direction: column;
  height: 500px;
  flex: 1 1 auto;

  .chatHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    width: 100%;
    height: 72px;
    flex-shrink: 0;
    -webkit-box-align: center;
    border-bottom: solid 1px rgba(145, 158, 171, 0.2);
    padding: 1rem;

    .chatHeaderActions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;
    }

    &Info {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .data {
        display: flex;
        align-items: center;
      }
    }

    h3 {
      text-transform: capitalize;
    }
  }
  .messageContainer {
    display: flex;
    flex-direction: column;
    min-width: 0;
    flex: 1 1 0;
    overflow-y: auto;
    .chatMessageList {
      padding: 1rem;
    }
    & *:not(:first-child) {
      margin-top: 1rem;
    }
    .message {
      display: flex;
      .messageInfo {
        word-wrap: break-word;
        max-width: 70%;
        border-radius: 10px 10px 0 10px;
        letter-spacing: 0.5px;
        line-height: 20px;
        padding: 0.75rem;
        text-align: initial;
        p {
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
        span {
          font-size: 0.75rem;
          line-height: 1rem;
          opacity: 0.75;
          margin-top: 0.25rem;
          display: block;
        }
      }
      .messageFile{
        word-wrap: break-word;
        max-width: 30%;
        border-radius: 10px 10px 0 10px;
        letter-spacing: 0.5px;
        line-height: 20px;
        padding: 0.75rem;
        text-align: initial;
        a {
          color: #006bff;
          text-decoration: underline;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img {
          max-width: 100%;
          height: auto;
          cursor: pointer;
        }
        span {
          font-size: 0.75rem;
          line-height: 1rem;
          opacity: 0.75;
          margin-top: 0.25rem;
          display: block;
        }
      }
      .backgroundUser {
        background-color: #325370;
        p {
          color: #ffffff;
        }
        span {
          color: #ffffff;
        }
      }
      .backgroundOther {
        background-color: #ffffff;
        p {
          color: #132b41;
        }
        span {
          color: #132b41;
        }
      }
    }
    .justifyEnd {
      justify-content: flex-end;
    }
    .justifyStart {
      justify-content: flex-start;
    }
    .loadingState {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  .chatFooter {
    border-top: solid 1px rgba(145, 158, 171, 0.2);
    padding: 1rem;
    max-height: 125px;
    .chatFooterContent {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .textAreaContainer {
        flex: 1 1 0;
        position: relative;
        .textAreaField {
          padding: 8px;
          box-sizing: border-box;
          flex-grow: 1;
          position: relative;
          width: 100%;
          border-radius: 0.5rem;
          min-height: 1em;
          max-height: 100px;
          overflow: hidden;
          resize: none;
          border: solid 1px rgba(145, 158, 171, 0.3);
          transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out;

          &:focus {
            border: none;
            outline: none;
            box-shadow: 0 0 0 2px #9ab8e8;
          }
        }
        span {
          position: absolute;
          right: 0.5rem;
          bottom: 0.5rem;
          font-size: 0.75rem;
          line-height: 1rem;
          color: #9ca3af;
        }
      }
      .chatFooterClipButton {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.6rem;
        background-color: rgb(38, 50, 56);
        border-radius: 9999px;
        border: none;
        cursor: pointer;
        color: white;
        height: 2rem;
        width: 2rem;
        &:hover {
          background-color: rgb(38, 50, 56);
        }
      }
      .chatFooterSendButton {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.6rem;
        background-color: rgb(38, 50, 56);
        border-radius: 9999px;
        border: none;
        cursor: pointer;
        color: white;
        height: 2rem;
        width: 2rem;
        &:hover {
          background-color: rgb(38, 50, 56);
        }
      }
    }
  }
}

// **** ChatEmptyState ***** //
.emptyChat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 95%;
  overflow-y: hidden;
  &Image {
    height: 120px;
    width: auto;
    opacity: 0.8;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }
  }

  &Text {
    h6 {
      font-size: 1.125rem;
      color: #919eab;
      font-weight: 600;
      line-height: 1.55556;
    }

    p {
      font-size: 0.875rem;
      line-height: 1.57143;
      font-weight: 400;
      color: #919eab;
      margin: 0;
    }

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }

    @media (max-width: 480px) {
      font-size: 0.8rem;
    }
  }
}

// **** ChatHeaderControls ***** //

.chatControl {
  display: flex;
  gap: 0.5rem;
  &Button {
    color: #374151;
    border: none;
    cursor: pointer;
    background-color: transparent;
    transition: color 0.3s;
    &:hover {
      color: #6b7280;
    }
  }
}
