.modalContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 520px;
  gap: 1.5rem;
  border-style: none;
  padding-block: 1.5rem;
  padding-inline: 2.25rem;

  .closeButton {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;

    .modalImage {
      display: flex;
      justify-content: center;
    }
    .modalTitle {
      line-height: 42px;
      font-weight: 700;
      font-size: var(--font-size-md);
      align-self: center;
    }
    p {
      margin-bottom: 0.7rem;
    }
  }
}
