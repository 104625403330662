@mixin flexible($display, $direction, $corte, $just, $align) {
  display: $display;
  flex-flow: $direction $corte;
  justify-content: $just;
  align-items: $align;
}

@mixin flexbox(
  $direction: row,
  $justifyContent: flex-start,
  $alignItems: flex-start
) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

// Grid
@mixin grid($typeNotation, $direction, $numberRepeatTracks, $trackDefinition) {
  display: grid;
  @if $typeNotation == repeat {
    @if $direction == rows {
      grid-template-rows: repeat($numberRepeatTracks, $trackDefinition);
    } @else if $direction == columns {
      grid-template-columns: repeat($numberRepeatTracks, $trackDefinition);
    }
  }
}

// Media Queries

@mixin tablet() {
  @media screen and (min-width: 43rem) {
    @content;
  }
}

@mixin desktop() {
  @media screen and (min-width: 62rem) {
    @content;
  }
}
