@import "../../ecommerce/styled-components/mixins.scss";
.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 60vh;
  padding: 24px;
}

.image {
  width: 250px;
  height: auto;
  margin-bottom: 2rem;
  padding-right: 1rem;
  img {
    width: 100%;
    height: 100%;
  }

  @include tablet {
    width: 300px;
  }
}

.icon svg {
  font-size: 6rem;
  margin-bottom: 16px;
  color: #e0e0e0;
}

.title {
  font-size: clamp(1.2rem, 2vw, 1.5rem);
  font-weight: 700;
  color: #4f4f4f;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.5rem;
  text-wrap: pretty;
}

.message {
  font-size: 0.9rem;
  text-align: left;
  color: #a09a9a;
  max-width: 50rem;
}

.children {
  margin-top: 16px;
}
