:root {
  --black-color: #000000;

  --bg-color: #ffffff;
  --bg-color-2: rgba(135, 133, 133, 0.9);
  --bg-color-3: #cacaca;
  --bg-color-4: rgba(245, 246, 249, 1);

  --main-color: #4f4f4f;

  --primary-color: #1c608c;
  --secondary-color: #54595f;

  --text-color: var(--main-color);
  --text-light-color: #ffffff;
  --text-muted: #9ca0a0;

  --track-color: #202324;
  --thumb-color: #454a4d;
  --thumb-hover-color: #596268;

  /* ===== TYPOGRAPHY */
  --font-size-xs: clamp(0.875rem, 1vw + 0.5rem, 1rem);
  --font-size-sm: clamp(1rem, 1.2vw + 0.5rem, 1.25rem);
  --font-size-md: clamp(1.25rem, 1.5vw + 0.5rem, 1.5rem);
  --font-size-lg: clamp(1.5rem, 2vw + 0.5rem, 2rem);
  --font-size-xl: clamp(2rem, 2.5vw + 0.5rem, 2.5rem);
  --font-size-xxl: clamp(2.5rem, 3vw + 0.5rem, 3.5rem);
}
