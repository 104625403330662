.buttonStatus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 0.375rem;
  padding-block: 0.5rem;
  text-align: left;
  border: none;
  background-color: transparent;
  &Text {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    span:nth-of-type(2) {
      color: #374151;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
}

.profileInfo {
  display: flex;
  align-items: center;
  padding: 0.5rem;

  &Name {
    margin-left: 0.5rem;
    h6 {
      font-size: 1rem;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #1c252e;
    }
    p {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.57143;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

// **** StatusOptionsMenu **** //

.statusMenuContent {
  margin-top: 0.5rem;
  width: 10rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  border-width: 1px;
  border-color: #f3f4f6;

  &Subtitle {
    padding-inline: 1rem;
    padding-bottom: 0.5rem;
    border-bottom-width: 1px;
    border-color: #f3f4f6;
    h3 {
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 500;
      color: #374151;
    }
  }
  &Info {
    padding: 0.5rem;
    button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      width: 100%;
      padding-inline: 1rem;
      padding-block: 0.5rem;
      text-align: left;
      border: none;
      background-color: transparent;
      &:hover {
        background-color: #f9fafb;
      }
    }
  }
}
.online {
  color: #00a650;
}
.away {
  color: #fbbf24;
}
.offline {
  color: #9ca3af;
}

.linkConfiguration {
  text-decoration: none;
  color: #000;
}