*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  min-height: -webkit-fill-available;
  font-family: "Poppins", sans-serif !important;

  @include desktop {
    font-size: 15px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: var(--text-color);
  font-family: "Poppins", sans-serif;
}

li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* General scrollbar styles */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track (the part where the scrollbar moves along) */
  ::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 10px;
  }

  /* Thumb (the part that can be dragged) */
  ::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
    border: 2px solid transparent;
  }

  /* Thumb hover (changes when the user hovers over the thumb) */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #b0b0b0;
  }
}
