@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "~react-image-gallery/styles/css/image-gallery.css";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Source+Sans+Pro:wght@400;600;700&display=swap");

@import "./services/breakpoints.scss";
@import "~react-toastify/dist/ReactToastify.min.css";

// GLOBAL STYLES
@import "assets/styles/global";

// Base
@import "assets/styles/partials/base/mixins";
@import "assets/styles/partials/base/common";
@import "assets/styles/partials/base/variables";
@import "assets/styles/partials/base/reset";

// GLOBAL COMPONENTS
@import "components/MainSlider/MainSlider";

/*======================================
=            Ecommerce Suppliers         =
========================================*/

// Pages
@import "supplierEcommerce/pages/Home/styled-components/Home";
@import "supplierEcommerce/pages/Products/Products";

// components
@import "supplierEcommerce/pages/Home/components/CategoryList/CategoryList";
@import "supplierEcommerce/pages/Home/components/DiscountedProducts/DiscountedProducts";
@import "supplierEcommerce/pages/Home/components/Trending-products/TrendingProducts";
@import "supplierEcommerce/pages/Home/components/VideoVirtualConsultancy/VideoVirtualConsultancy";
@import "supplierEcommerce/pages/Home/components/VirtualConsultancyBanner/VirtualConsultancyBanner";
@import "supplierEcommerce/components/ProductCard/ProductCard";
@import "supplierEcommerce/pages/ProductInformation/styled-components/ProductInformation";
@import "supplierEcommerce/pages/ProductInformation/components/ProductFeatures/ProductFeatures";
@import "components/CommonFooter/CommonFooter";
@import "supplierEcommerce/components/Jumbotron/Jumbotron";
@import "supplierEcommerce/pages/ProductInformation/components/RelatedProductsSlider/RelatedProductsSlider";

/*======================================
=            Checkout Suppliers         =
========================================*/

@import "supplierCheckout/components/HeaderCheckout/HeaderCheckout";

// PAGES
@import "assets/styles/products";
@import "assets/styles/home";
@import "assets/styles/order";
@import "assets/styles/login";
@import "assets/styles/workWithUs";
@import "assets/styles/adminPanel";

// PRIVATE PAGEs
@import "assets/styles/privatePages";

/******  SUPPLIER  *******/

// Components

@import "privatePages/supplier/pages/Preferences/components/BrandingPreferences/BrandingPreferences";
@import "privatePages/supplier/pages/Preferences/components/BannerPreferences/BannerPreferences";

@import "privatePages/supplier/pages/Preferences/components/LogoUploader/LogoUploader";
@import "privatePages/supplier/pages/SupplierSettings/components/Wompi/Wompi";
@import "privatePages/supplier/pages/SupplierSettings/components/Envia/Envia";
@import "privatePages/supplier/components/SalesPersonForm/SalesPersonForm.scss";
