.knockButton {
  padding: 0.6rem;
  background-color: #f0f0f0;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54);
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: #e7e7e7;
  }
}
