.product-features {
  margin: auto 0;
  display: flex;
  flex-direction: column;
  padding-inline: 1rem;
  padding-top: 2rem;
  p {
    color: #989797;
  }

  @include tablet {
    padding-inline: 2rem;
    padding-top: 0;
  }
}

.top {
  margin-bottom: 2rem;
  @include flexible(flex, row, nowrap, space-between, flex-start);

  &__title {
    width: 90%;
    @include flexible(flex, column, nowrap, flex-start, flex-start);

    h1 {
      width: 100%;
      font-weight: 500;
      font-size: 19px;
      letter-spacing: 0;
      line-height: 1.9rem;
      margin-bottom: 1rem;
      @include tablet {
        font-size: 28px;
      }
    }

    .unitsPerPackage {
      font-weight: 500;
      font-size: 1rem;
      letter-spacing: 0;
      color: #4f4f4f;
      line-height: 2.2rem;
    }

    .stock-notice {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      color: var(--text-muted);
      margin-top: 16px;
    }

    .pricing-notice {
      font-size: 12px;
      color: var(--text-muted);
      margin-top: 24px;
      margin-bottom: 16px;
    }

    span {
      font-weight: 500;
      font-size: 1.3rem;
      letter-spacing: 0;
      color: var(--primary-color);
    }
  }
  &__icons {
    width: 20%;
    @include flexible(flex, row, nowrap, flex-end, center);
    gap: 0.5rem;

    div {
      @include flexible(flex, row, nowrap, center, center);
      height: 2.5rem;
      width: 2.5rem;
      color: var(--text-light-color);
      text-decoration: none;
      font-size: 20px;
      background-color: var(--primary-color);
      padding: 0px 10px;
      transition: 0.6s;
      border-radius: 100%;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }
    }

    @media screen and (max-width: 600px) {
      a {
        padding: 5px;
      }
    }
  }
}

.main {
  font-family: "Poppins", sans-serif;
  div {
    margin-bottom: 0.9375em;
    h5 {
      font-weight: 600;
      font-size: 1rem;
      margin: 0;
      padding: 0;
      letter-spacing: 0;
      line-height: 1.5rem;
      margin-bottom: 0.3rem;
    }
    span {
      font-weight: 300;
      font-size: 1rem;
      letter-spacing: 0;
      line-height: 1.5rem;
    }
  }

  &__color {
    margin-bottom: 0.9375em;
    @include flexible(flex, row, nowrap, flex-start, center);
    gap: 0.7rem;
    .circularColor {
      margin: 0;
      height: 1.8em;
      width: 1.8em;
      border-radius: 100%;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    }
  }
}

.file-container {
  @include flexible(flex, row, nowrap, flex-start, center);
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  span {
    font-weight: 500;
    font-size: 1rem;
  }

  a {
    @include flexible(flex, row, nowrap, center, center);
    height: 2.5rem;
    width: 2.5rem;
    color: var(--text-light-color);
    text-decoration: none;
    font-size: 20px;
    background-color: var(--primary-color);
    padding: 0px 10px;
    transition: 0.6s;
    border-radius: 100%;
    margin-left: 0.5em;

    &:hover {
      opacity: 0.9;
    }
  }

  @media screen and (max-width: 600px) {
    a {
      padding: 5px;
    }
  }
}

.terms-conditions {
  width: 100%;
  font-size: 0.9rem;
  margin-block: 1rem;
  a:link {
    color: var(--primary-color);
    font-weight: 400;
    font-size: 14px;
    text-decoration: underline;
  }
}

.virtualConsultancy {
  width: 100%;
  p {
    margin-block: 1rem;
  }
}

.boxCalculator {
  background: rgb(245, 245, 245);
  padding-bottom: 1.5rem;
  margin-bottom: 0.7rem;

  .container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    height: 100%;

    .title {
      h2 {
        margin: 0;
        color: #4f4f4f;
        font-size: 18px;
        font-weight: 700;
        padding: 5px;
      }
    }
    .content {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;

      &__input {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-left: 10px;
        border-right: 1px solid #1b262e;
        width: 50%;

        h2 {
          font-size: 14px;
          color: #1b262e;
          font-weight: 400;
          margin-top: 0;
          text-align: left;
          padding-right: 10px;
          margin-bottom: 1rem;
        }

        div {
          display: flex;
          width: 100%;
          justify-content: flex-start;
          input {
            font-size: 14px;
            border: 1px solid #676868;
            width: 60%;
            line-height: 1.5rem;
            font-weight: 400;
            text-align: center;
            font-style: italic;
            margin-right: 0.5rem;
            padding: 0.5rem 0.2rem;
            color: rgb(51, 51, 51);
            background-color: rgb(255, 255, 255);
          }
          p {
            color: #1b262e;
            font-size: 100%;
            font-weight: 400;
            font-style: italic;
            margin: auto 0;
          }
        }
      }
      &__information {
        width: 50%;
        margin-left: 25px;
        display: flex;
        justify-content: flex-start;
        position: relative;

        .info {
          width: 85%;

          h2 {
            white-space: normal;
            font-size: 14px;
            color: #1b262e;
            font-weight: 500;
            margin-top: 0;

            strong {
              font-weight: bolder;
              font-size: 14px;
              color: #1b262e;
            }
          }
          div {
            display: flex;
            align-items: center;
            gap: 0.6rem;
            strong {
              span {
                font-size: 14px;
                color: #1b262e;
                font-weight: 400;
                margin-top: 0;
                margin-left: 0.2rem;
              }
            }
            img {
              width: 1.2rem;
            }
          }
        }
      }
    }
  }
}
