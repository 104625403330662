.container {
  max-width: 90rem;
  margin-inline: auto;
  padding-inline: 1rem;

  @media (min-width: 640px) {
    padding-inline: 1.5rem;
  }
  @media (min-width: 1024px) {
    padding-inline: 2rem;
  }
}

.section {
  padding-block: 2rem;

  @include tablet {
    padding-block: 5rem;
  }
}

.margin-block {
  margin-block: 1.5rem;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hr-separator {
  border: none;
  width: 100%;
  height: 1px;
  background-color: rgba(145, 158, 171, 0.2);
  margin: 20px 0;
}

.paper-container {
  padding: 1rem;
  margin-inline: auto;
  width: 100%;
  flex: 1;

  @include tablet {
    padding: 2rem;
  }
}

.subtitle-1 {
  font-size: var(--font-size-xs);
  line-height: 1.5;
  font-weight: 500;
  color: var(--text-color);
}

.subtitle-2 {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 600;
  color: var(--text-color);
}

.body-1 {
  font-size: var(--font-size-xs);
  line-height: 24px;
  font-weight: normal;
  color: var(--text-color);
}

.body-2 {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: var(--text-color);
}

.caption {
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  color: var(--text-color);
}

.overline {
  font-size: 10px;
  line-height: 14px;
  font-weight: normal;
  text-transform: uppercase;
  color: var(--text-color);
}

.text-capitalize {
  text-transform: capitalize;
}

.preferencesActionButtonContainer {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  img {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
