.productTitle {
  padding-inline: 1rem;
  padding-top: 1rem;
  text-transform: capitalize;
  h2 {
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 500;
    color: #111827;
  }
}
.mainImage {
  padding: 1rem;
  div {
    border-radius: 1rem;
    overflow: hidden;
    background-color: #dbeafe;
    height: 12rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.advisorInfo {
  padding-inline: 1rem;
  padding-bottom: 0.75rem;
  div {
    display: flex;
    align-items: center;
    .advisorImage {
      position: relative;
      img {
        width: 2rem;
        height: 2rem;
        border-radius: 9999px;
        object-fit: cover;
      }
    }
    .advisorName {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 0.75rem;
      h4 {
        font-weight: 500;
        color: #111827;
      }
      p {
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #4b5563;
        text-transform: capitalize;
      }
    }
  }
}

.advisorButton {
  padding: 1rem;
}
