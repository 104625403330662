.avatarButton {
  position: relative;
  border: none;
  background-color: transparent;
  cursor: pointer;

  .avatarWrapper {
    position: relative;

    .avatarImg {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      object-fit: cover;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: border-color 0.3s;
    }

    .withBorder {
      border: 2px solid #4f46e5;

      &:hover {
        border-color: #4338ca;
      }
    }

    .noBorder {
      border: none;
    }

    .statusIndicator {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
      border: 2px solid #fff;

      &.online {
        background-color: #22c55e;
      }

      &.offline {
        background-color: #9ca3af;
      }
    }
  }
}
